:root {
  --surface-color: white;
  --curve: 35;
}

.card {
  position: relative;
  display: block;
  border-radius: calc(var(--curve) * 1px);
  border: 1px solid rgba(0, 0, 0, 0.24);
  overflow: hidden;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.card__image {
  width: 100%;
  border-radius: 30px;
  height: 99%;
}

.card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: calc(var(--curve) * 1px);
  background-color: var(--surface-color);
  transform: translateY(100%);
  transition: 0.2s ease-in-out;
}

.card:hover .card__overlay {
  transform: translateY(0);
}

.card__header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  padding: 1.4em;
  border-radius: calc(var(--curve) * 1px) 0 0 0;
  background-color: var(--surface-color);
  transform: translateY(-100%);
  transition: 0.2s ease-in-out;
}

.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 1;
}

.card__arc path {
  fill: var(--surface-color);
  d: path('M 40 80 c 22 0 40 -22 40 -40 v 40 Z');
}

.card:hover .card__header {
  transform: translateY(0);
}

.card__thumb {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.card__header-text {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.card__title {
  font-size: 1em;
  margin: 0 0 0.3em;
  color: rgb(3, 37, 65);
}

.card__tagline {
  display: block;
  margin: 1em 0;
  font-family: 'MockFlowFont';
  font-size: 0.8em;
  color: rgb(3, 37, 65);
}

.card__status {
  font-size: 0.8em;
  color: rgb(3, 37, 65);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  border-radius: 5px;
  height: 20px;
  background: linear-gradient(to right, #1ed5a9 0%, #01b4e4 100%);
}

.card__description {
  margin: 0;
  font-size: 13px;
  color: rgb(3, 37, 65);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  margin-bottom: 25px;
}
.card__languaje {
  margin: 0;
  padding: 0;
  font-size: 13px;
  display: -webkit-box;
  color: rgb(3, 37, 65);
}

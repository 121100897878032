.search-movie-input {
  width: 60%;
  height: 40px;
  border: 1px solid black;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  margin-top: 10px;
  margin-bottom: 10px;
}
input {
  border: none;
  width: 100%;
  height: 30px;
  margin: 20px;
  background: transparent;
}
input:focus {
  outline: none;
}

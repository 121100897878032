.custom-buttom {
  width: 150px;
  height: 40px;
  background: linear-gradient(
    to right,
    rgba(30, 213, 169, 1) 0%,
    rgba(1, 180, 228, 1) 100%
  );
  display: flex;
  justify-content: center;
  border-radius: 25px;
}
.custom-buttom:hover {
  color: white;
  border: 1px solid white;
  cursor: pointer;
}

button {
  background: transparent;
  border: none;
  color: white;
}
button:hover {
  cursor: pointer;
}
button:focus {
  width: 100%;
  border: 1px solid white;
  border-radius: 25px;
}

.custom-top-list {
  display: flex;
  width: 100%;
  height: 100%;
  background: red;
}
.cards {
  display: grid;
  gap: 1rem;
  grid-auto-rows: 22rem;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
}

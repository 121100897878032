@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700,800');

.info-page-search-movie-bodyguard {
  background: rgba(45, 57, 68, 0.2);
}
.info-page-search-movie-bodyguard-light {
  background: rgba(45, 57, 68, 0.75);
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.info-page-search-movie-bodyguard-bold {
  background: rgba(45, 57, 68, 0.75);
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.info-page-modal-body {
  position: relative;
  display: block;
  width: 60%;
  height: 75vh;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  border-radius: 10px;
  transition: all 0.4s;
  &:hover {
    transform: scale(1.02);
    transition: all 0.4s;
  }
}
.movie_card {
  position: absolute;
  display: block;
  width: 60%;
  height: 450px;
  overflow: hidden;
  border-radius: 10px;
  transition: all 0.4s;
  &:hover {
    transform: scale(1.02);
    transition: all 0.4s;
  }
  .info_section {
    position: relative;
    width: 100%;
    height: 100%;
    background-blend-mode: multiply;
    text-align: start;
    z-index: 1000002;
    border-radius: 10px;
    .movie_header {
      position: relative;
      padding: 25px;
      height: 40%;
      h1 {
        color: #fff;
        font-weight: 400;
      }
      h4 {
        color: #9ac7fa;
        font-weight: 400;
      }
      .minutes {
        display: inline-block;
        margin-top: 10px;
        color: #fff;
        padding: 5px;
        border-radius: 5px;
        border: 1px solid rgba(255, 255, 255, 0.13);
      }
      .type {
        display: inline-block;
        color: #cee4fd;
      }
      .locandina {
        position: relative;
        float: left;
        margin-right: 20px;
        height: 120px;
        box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.5);
      }
    }
    .movie_header_arrow {
      color: white;
      font-weight: bold;
      font-size: 16px;
      cursor: pointer;
    }
    .movie_desc {
      padding: 25px;
      height: 50%;
      .text {
        color: #cfd6e1;
      }
    }
    .movie_social {
      height: 10%;
      padding-left: 15px;
      padding-bottom: 20px;
      ul {
        list-style: none;
        padding: 0;
        li {
          display: inline-block;
          color: rgba(255, 255, 255, 0.4);
          transition: color 0.3s;
          transition-delay: 0.15s;
          margin: 0 10px;
          &:hover {
            transition: color 0.3s;
            color: rgba(255, 255, 255, 0.8);
          }
          i {
            font-size: 19px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .blur_back {
    position: absolute;
    top: 0;
    z-index: 1000001;
    height: 100%;
    right: 0;
    background-size: cover;
    border-radius: 11px;
  }
}

@media screen and (min-width: 768px) {
  .movie_header {
    width: 60%;
  }

  .movie_desc {
    width: 50%;
  }

  .info_section {
    background: linear-gradient(to right, #032541 50%, transparent 100%);
  }

  .blur_back {
    width: 80%;
    background-position: -100% 10% !important;
  }
}

@media screen and (max-width: 768px) {
  .movie_card {
    width: 95%;
    margin: 70px auto;
    min-height: 350px;
    height: auto;
  }

  .blur_back {
    width: 100%;
    background-position: 50% 50% !important;
  }

  .movie_header {
    width: 100%;
    margin-top: 85px;
  }

  .movie_desc {
    width: 100%;
  }

  .info_section {
    background: linear-gradient(to top, #032541 50%, transparent 100%);
    display: inline-grid;
  }
}

#bright {
  box-shadow: 0px 0px 150px -45px rgba(255, 51, 0, 0.5);
  &:hover {
    box-shadow: 0px 0px 120px -55px rgba(255, 51, 0, 0.5);
  }
}

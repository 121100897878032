.home-search-movie {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.home-search-movie-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
  height: 100%;
}
.home-search-movie-body-search-component {
  width: 100%;
  height: 75px;
  position: fixed;
  top: 55px;
  bottom: 0;
  z-index: 9999;
  margin-top: 0px;
  margin-bottom: 10px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-search-movie-body-list-component {
  width: 70%;
  margin-top: 150px;
}
.home-search-movie-body-pagination-component {
  display: flex;
  height: 80px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.home-search-movie-footer {
  position: fixed;
  bottom: 0;
  height: 30px;
  width: 100%;
  background: rgba(3, 37, 65, 1);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 13px;
}

.custom-pagination-arrow-body {
  margin: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.custom-pagination-body {
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-pagination-arrow {
  color: black;
  cursor: pointer;
}
.custom-pagination-arrow:hover {
  color: rgb(1, 180, 228);
}
